import React, { useState } from "react";
import "./css/singlePost.css";
import img from "../assets/images/post1.webp";
import img2 from "../assets/images/post2.webp";
import FavoriteBorderSharpIcon from "@mui/icons-material/FavoriteBorderSharp";
import InsertLinkSharpIcon from "@mui/icons-material/InsertLinkSharp";
import SearchIcon from "@mui/icons-material/Search";
import { NavHashLink as Link } from "react-router-hash-link";
import { useParams } from "react-router-dom";

export default function SinglePost() {
  const posts = [
    {
      id: 1,
      date: "17 feb.",
      title: "Vi lanserar en ny hemsida!",
      body: " Föreningen Impact Mind lanserar idag sin egen hemsida! Vi fortfarande håller på att arbeta med innehållet, men det går redan att läsa...",
      img: img,
    },
    {
      id: 2,
      date: "21 jan.",
      title: "Bli medlem i föreningen",
      body: "Vill du bli en del av oss? Välkommen som medlem i föreningen Impact Mind! Som medlem i föreningen Impact Mind får du följande..",
      img: img2,
    },
  ];
  const { id } = useParams();
  const postDetails = posts.find((post) => post.id == id);
  const latestPosts = posts.filter((post) => id != post.id);
  console.log(postDetails);
  console.log(latestPosts);
  console.log(id);

  return (
    <div className="single-post animate__animated animate__fadeIn">
      <div className="container">
        <div className="header">
          <Link to="/news">Alla nyheter</Link>
          {/* <SearchIcon /> */}
        </div>
        <div className="post-sec">
          <span className="date">{postDetails.date}</span>
          <h3>{postDetails.title}</h3>
          {id == 2 && <span className="date">Uppdaterat: 17 feb.</span>}
          <p>{postDetails.body}</p>
          {id == 2 && (
            <div>
              <ul>
                <li>Få 10% rabatt på terapi hos oss</li>
                <li>
                  Få tillgång till information och utbildningar för medlemmar
                  där du kan lära dig mer om psykisk hälsa samt både populära
                  och innovativa terapimetoder
                </li>
                <li>
                  tillgång till föreningens aktiviteter för medlemmar och skapa
                  nya kontakter
                </li>
                <li>
                  Få rabatterat pris på våra större evenemang (t.ex.
                  konferenser)
                </li>
                <li>
                  Kandidera dig till styrelsen och få erfarenhet av att driva en
                  ideell organisation - förbättra ditt CV och karriärmöjligheter
                </li>
                <li>
                  Förverkliga dina egna idéer och projekt under föreningens namn
                  och agera som rörelsens röstbärare - bidra till ökad mental
                  hälsa i samhället
                </li>
                <li>
                  Stödja våra aktiviteter genom gåvor eller med sponsring och få
                  kostnadsfritt entré till våra evenemang samt marknadsföra dig
                  och din organisation genom våra kanaler och bidra till din
                  organisations Corporate Social Responsibility
                </li>
              </ul>
              <span>
                Medlemsavgiften är 100 kr per kalenderår och betalas till
                BankGiro 5663-3647.
              </span>
            </div>
          )}
          <img src={postDetails.img} alt="post" />
          {/* <div className="socials">
            <a href="#">
              <i class="fa-brands fa-facebook-f"></i>
            </a>
            <a href="#">
              <i class="fa-brands fa-twitter"></i>
            </a>
            <a href="#">
              <i class="fa-brands fa-linkedin-in"></i>
            </a>
            <InsertLinkSharpIcon />
          </div>
          <div className="comments-and-likes">
            <span>0 kommentarer</span>
            <FavoriteBorderSharpIcon color="error" />
          </div> */}
        </div>
        <div className="latest-posts-sec">
          <div className="latest-posts-header">
            <span>Senaste inlägg</span>
            <Link to="/news">Visa alla</Link>
          </div>
          <div className="posts">
            {latestPosts.map((post) => (
              <div key={post.id} className="post">
                <Link
                  to={`/single-post/${post.id}/${post.title}`}
                  onClick={window.scrollTo({ top: 0 })}
                >
                  <img src={post.img} alt="post" />
                </Link>
                <div className="post-details">
                  <Link
                    to={`/single-post/${post.id}/${post.title}`}
                    onClick={window.scrollTo({ top: 0 })}
                  >
                    <h4>{post.title}</h4>
                  </Link>
                  {/* <div className="comments-and-likes">
                    <i class="fa-regular fa-message">
                      <span>0</span>
                    </i>
                    <FavoriteBorderSharpIcon fontSize="small" color="error" />
                  </div> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
