import React, { useState } from "react";
import "./css/navbar.css";
import logo from "../assets/images/logo.png";
import { NavHashLink as Link } from "react-router-hash-link";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export default function Navbar() {
  const [dropAbout, setDropAbout] = useState(false);
  const [dropForm, setDropForm] = useState(false);
  const [menu, setMenu] = useState(false);

  return (
    <div onClick={() => dropAbout && setDropAbout(false)} className="navbar">
      <div className="top-nav">
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <a href="https://impactmind.us14.list-manage.com/subscribe?u=f9bbaa879b25224967f36aa03&id=c1fccc863f" target="_blank" rel="noopener noreferrer" className="signup-btn">
          Bli medlem
        </a>
      </div>
      <hr />
      <div className="bottom-nav">
        <ul>
          <li>
            <Link to="/">Hem</Link>
          </li>
          <li>
            <Link to="/news">Nyheter</Link>
          </li>
          <li
            style={{ position: "relative" }}
            onMouseEnter={() => setDropForm(true)}
            onMouseLeave={() => setDropForm(false)}
          >
            <Link to="/forms-of-therapy">Terapiformer</Link>
            {dropForm && (
              <div className="dropdown dropdown-forms">
                <ul>
                  <li>
                    <Link to="/forms-of-therapy/#1">
                      CFT - Cmpassionfokuserad terapi
                    </Link>
                  </li>
                  <li>
                    <Link to="/forms-of-therapy/#2">
                      ACT - Acceptans and commitment terapi
                    </Link>
                  </li>
                  <li>
                    <Link to="/forms-of-therapy/#3">
                      KBT - Kognitiv brteendeterapi
                    </Link>
                  </li>
                  <li>
                    <Link to="/forms-of-therapy/#4">Problematik</Link>
                  </li>
                </ul>
              </div>
            )}
          </li>
          <li>
            <Link to="/familjehem">Familjehem</Link>
          </li>
          <li>
            <button
              className="nav-btn"
              // onClick={() => setDropAbout(!dropAbout)}
              onMouseEnter={() => setDropAbout(true)}
              onMouseLeave={() => setDropAbout(false)}
            >
              Om oss
              {dropAbout && (
                <div className="dropdown">
                  <ul>
                    <li>
                      <Link to="/about-us">Om oss</Link>
                    </li>
                    <li>
                      <Link to="/statutes">Stdgar</Link>
                    </li>
                    <li>
                      <Link to="/data-policy">Datapolicy</Link>
                    </li>
                  </ul>
                </div>
              )}
            </button>
          </li>
          <li>
            <Link to="/support-us">Stöd oss</Link>
          </li>
          <li>
            <Link to="/#contact">Kontakt</Link>
          </li>
        </ul>
        <div className="socials">
          <a href="#">
            <i class="fa-brands fa-facebook-f"></i>
          </a>
          <a href="#">
            <i class="fa-brands fa-instagram"></i>
          </a>
          <a href="#">
            <i class="fa-brands fa-linkedin-in"></i>
          </a>
        </div>
      </div>

      <i
        class="fa-sharp fa-solid fa-bars menu"
        onClick={() => setMenu(true)}
      ></i>
      {menu && (
        <div className="mob-nav">
          <i
            class="fa-sharp fa-solid fa-xmark close"
            onClick={() => setMenu(false)}
          ></i>
          <ul className="ul1">
            <li>
              <Link to="/" onClick={() => setMenu(false)}>
                Hem
              </Link>
            </li>
            <li>
              <Link to="/news" onClick={() => setMenu(false)}>
                Nyheter
              </Link>
            </li>
            <li
              className="mob-drop-clicker"
              onClick={() => setDropForm(!dropForm)}
            >
              <Link to="/forms-of-therapy" onClick={() => setMenu(false)}>
                Terapiformer
              </Link>
              <KeyboardArrowDownIcon
                sx={{ position: "absolute", right: "27%" }}
              />
            </li>
            {dropForm && (
              <li className="mob-dropdown">
                <ul className="ul2">
                  <li>
                    <Link to="/forms-of-therapy/#1">
                      CFT - Cmpassionfokuserad terapi
                    </Link>
                  </li>
                  <li>
                    <Link to="/forms-of-therapy/#2">
                      ACT - Acceptans and commitment terapi
                    </Link>
                  </li>
                  <li>
                    <Link to="/forms-of-therapy/#3">
                      KBT - Kognitiv brteendeterapi
                    </Link>
                  </li>
                  <li>
                    <Link to="/forms-of-therapy/#4">Problematik</Link>
                  </li>
                </ul>
              </li>
            )}
            <li>
              <Link to="/familjehem" onClick={() => setMenu(false)}>Familjehem</Link>
            </li>
            <li
              className="mob-drop-clicker"
              onClick={() => setDropAbout(!dropAbout)}
            >
              <button className="nav-btn">Om oss</button>
              <KeyboardArrowDownIcon
                sx={{ position: "absolute", right: "27%" }}
              />
            </li>
            {dropAbout && (
              <li className="mob-dropdown">
                <ul className="ul2" onClick={() => setMenu(false)}>
                  <li>
                    <Link to="/about-us">Om oss</Link>
                  </li>
                  <li>
                    <Link to="/statutes">Stdgar</Link>
                  </li>
                  <li>
                    <Link to="/data-policy">Datapolicy</Link>
                  </li>
                </ul>
              </li>
            )}
            <li>
              <Link to="/support-us" onClick={() => setMenu(false)}>
                Stöd oss
              </Link>
            </li>
            <li>
              <Link
                className="not-active"
                to="/#contact"
                onClick={() => setMenu(false)}
              >
                Kontakt
              </Link>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
