import React from "react";
import "./css/supportUs.css";
import img1 from "../assets/images/support1.webp";
import img2 from "../assets/images/support2.webp";
import img3 from "../assets/images/support3.webp";

export default function SupportUs() {
  return (
    <div className="support-us pd-y animate__animated animate__fadeIn">
      <div className="content">
        <h2>STÖD OSS</h2>
        <span>Du kan göra skillnad</span>
        <div className="row">
          <div className="col">
            <div className="img">
              <img src={img1} alt="" />
            </div>
            <h3>GE EN DONATION</h3>
            <p>
              Det här är ett av de enklaste sätten att stödja vårt arbete. Vi är
              övertygade om att vi når framgång genom att engagera vårt
              lokalsamhälle. Det är ett enkelt och effektivt sätt att bidra till
              det viktiga arbete som föreningen Impact Mind gör. Hör av dig och
              ta reda på hur du kan Ge en donation idag.
            </p>
          </div>
          <div className="col">
            <div className="img">
              <img src={img2} alt="" />
            </div>
            <h3>SAMARBETA MED OSS</h3>
            <p>
              Din generositet och ditt engagemang är alltid uppskattat hos
              föreningen Impact Mind eftersom varje bidrag hjälper oss att bli
              en/ett bättre Ideell förening. Vi vill ge dig all information du
              behöver som rör ditt engagemang, så tveka inte att höra av dig med
              dina frågor.
            </p>
          </div>
          <div className="col">
            <div className="img">
              <img src={img3} alt="" />
            </div>
            <h3>BLI MEDLEM</h3>
            <p>
              Vill du bidra till vårt arbete men vet inte var du ska börja?
              Utnyttja möjligheten att Bli medlem och bidra med ditt stöd. Det
              är ett utmärkt sätt att bidra till vårt arbete, och varje liten
              insats tar oss ett steg närmare en bättre morgondag. Kontakta oss
              idag för mer information om hur du kan hjälpa till.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
