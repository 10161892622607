import React from "react";
import "./css/familyHome.css"

export default function FamilyHome() {
    return (
        <div className="animate__animated animate__fadeIn">
            <h2>Familjehem</h2>
            <div className="family-home">
                <p>Att växa upp i en trygg och stöttande miljö är en grundläggande rättighet för alla barn och unga.
                    Tyvärr
                    har inte alla möjligheter att få den tryggheten i sina familjer av olika anledningar. Det är här vi
                    kommer in i bilden. Vårt mål är att erbjuda barn och unga som av olika skäl inte kan bo hos sina
                    föräldrar i en varm och kärleksfull familjemiljö där de kan känna sig omhändertagna och trygga.</p>

                <h3>För dig som vill bli familjehem hos oss</h3>
                <p>Som familjehem hos Impact Mind spelar ni en avgörande roll i att ge barn och unga en möjlighet till
                    en ny
                    start. Ni kommer att få möjlighet att öppna era hem och hjärtan för dem som behöver det som mest. Ni
                    kommer att vara en trygg hamn, en stabil plats där de kan känna sig sedda, förstådda och
                    omhändertagna.</p>

                <p>Att vara familjehem är tålamod och energikrävande samtidigt som det ger väldigt mycket tillbaka. Alla
                    –
                    med rätt resurser – kan vara familjehem oavsett om man är ensamstående, gift eller i partnerskap med
                    eller utan egna barn. Det finns dock några krav för att kunna bli familjehem.</p>

                <h3>Krav för att bli familjehem:</h3>
                <p>Ha tid, tålamod och ett engagemang utöver det vanliga att stötta och hjälpa barn och unga i deras
                    livssituation. Barnet/ungdomen som placeras kan ha varit med svåra upplevelser och behöver
                    hjälp.</p>
                <p>Ha ett ledigt eget rum där barnet eller ungdom får känna sig trygg (ej vardagsrum).</p>
                <p>Innan ni bli familjehem behöver du och din eventuella partner genomgå en utredning som består av
                    intervjuer, referenstagning och registeruppgifter från polisens misstanke- och belastningsregister,
                    socialregistret mm. Ibland är det vi som utreder er men många kommuner kräver att deras egna
                    personal
                    utreder er innan man kan ta emot placeringar från respektive kommun.</p>

                <h3>Stöd från oss</h3>
                <p>Vi på Impact Mind strävar efter att vara en stödjande och pålitlig partner för er som är intresserade
                    av
                    att bli familjehem. Vi erbjuder utbildning, handledning och kontinuerligt stöd för att säkerställa
                    att
                    ni har de verktyg och resurser som behövs för att vara framgångsrika familjehem.</p>

                <h3>Information om ersättning</h3>
                <p>Ersättningen är uppdelad i två delar: 1-en skattefri omkostnadsersättning och ett arvode som
                    beskattas.
                    Omkostnadsersättningen är enbart skattefri om den ligger inom ett visst belopp som fastställs varje
                    år
                    av Skatteverket. 2-Familjehemmets arvode räknas som lön i Skatteverkets mening och man får
                    lönespecifikationer (ej anställning och familjehem är ett uppdrags). Det innebär att arvodet är
                    sjukpenning‐ och pensionsgrundande men berättigar inte till tjänstepension, semesterlön eller
                    semesterersättning. Ett uppdrag berättigar inte heller till A-kassa.</p>

                <h3>Samarbete</h3>
                <p>Vi samarbetar nära myndigheter och andra partner för att säkerställa att alla barn och unga som
                    placeras
                    i våra familjehem får den bästa möjliga vård och stöd. Genom att erbjuda kompetenser inom socialt
                    arbete, flera terapiformer och andra relevanta områden kan vi erbjuda en holistisk och
                    individanpassad
                    vårdplan för varje barn och ungdom.</p>

                <p>Ni är välkomna att kontakt oss!</p>
            </div>
        </div>
    );
}
