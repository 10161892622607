import React, { useState, useRef } from "react";
import "./css/home.css";
import img1 from "../assets/images/post1.webp";
import img2 from "../assets/images/post2.webp";
import homeImg2 from "../assets/images/home2.jpg";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CircleIcon from "@mui/icons-material/Circle";
import { Link } from "react-router-dom";

export default function Home() {
  const ref = useRef(null);
  const [reviewNum, setReviewNum] = useState(1);

  let posts = [
    {
      id: 1,
      title: "Vi lanserar en ny hemsida!",
      date: "17 feb.",
      image: img1,
    },
    {
      id: 2,
      title: "Bli medlem i föreningen",
      date: "21 jan.",
      image: img2,
    },
  ];

  let reviewsContent = [
    {
      description:
        "Compassion mind training ger en helt ny dimension till KBT som leder till bättre självmedkänsla, värme till mig själv leder till att jag minskar stress, ångest och oro. Kombinationen med individuell terapi med olika mind training med olika rollspel leder till snabbare och bättre resultat äntraditionella KBT. Sima är en väldigt kunnig och inkännande terapeut. Hon är väldigt duktig på att tillämpa olika metoder och verktyg.",
      details: "Stig, 60 år",
    },
    {
      description:
        "Vanligtvis vill jag vara alla människor till lags, men inser att jag inte kan vara det om jag inte är mig själv till lags. Compassion har för mig blivit ett sätt att möta både mig själv och omgivningen, ”jag duger som jag är”. När jag fokuserar på mig själv med självmedkänsla så börjar jag lyssna på mig själv och mina behov. Jag har blivit min egen bästa vän, bemöter mig själv med värme och har blivit mer kreativ. Jag antar att det är för att jag har bättre kontakt med mig själv när jag har medkänsla för mig själv och kan kommunicera bättre med min omgivning. Nu kan jag sätta gränser på ett mer tydligt men ändå artigt sätt, behandlar jag även andra människor med större medkänsla nu än tidigare. Detta är bara ett par exempel på hur Compassion terapin har hjälpt mig att skapa en mer stabil grund i mitt liv.",
      details: "Anders, 36 år",
    },
    {
      description:
        "Viktigaste jag har tagit med mig är att jag förstår vad det är som händer när jag hamnar i olika svåra situationer, att trösta sig själv istället för att straffa sig själv. Om jag mår dåligt och får panik i en situation så vet jag efteråt vad som hänt i kroppen och hjärnan, och då blir det lättare att hantera. Compassion Mind trainingar men också ”stolarbete” har varit starka men jag har fått  bättre förståelse för mig själv, högre självmedkänsla, mindre prestationsångest, stress och rädsla. Generellt så har det varit superkul och jag har utvecklats mycket som människa av detta..",
      details: "Viktoria, 18 år",
    },
    {
      description:
        "Det har varit skönt att höra andra i Compassion gruppbehandlingen att tala om saker jag trott att bara jag känner, och att göra compassion mind training var en stark upplevelse. Individuella samtal där fick man möta sin sårbara sida av sig själv och acceptera den har bidragit till bättre  självmedkänsla, lägre prestationsångest, oro och stress.",
      details: "Sara, 28 år",
    },
    {
      description:
        "Jag fick lära mig att det inte är mitt fel men jag har ansvar för vad jag väljer. Det har fått mig att bättre förståelse för mig själv och hur min hjärna funkar och hjälper mig att ändra hur jag agerar i olika situationer till det bättre. Jag är mer medveten om mina val nu än tidigare, har fått bättre självmedkänsla och sänkt kraven på mig själv.",
      details: "Sofia, 35 år",
    },
    {
      description:
        "Compassion terapin gjorde det möjligt för mig att förstå hur min hjärna fungerar och hur det påverkar mig i olika situationer. Att ha lärt mig detta gjorde även det möjligt för mig att kunna hantera situationer bättre. Det som även är så bra med Compassion gruppbehandling är att man får dela med sig av personliga erfarenheter precis så mycket som man själv vill, samtidigt som man får veta att det man känner är man absolut inte ensam om.",
      details: "Carolina, 18 år",
    },
    {
      description:
        "Compassion terapi har hjälpt mig att bli medveten om hur hjärnan fungerar och att man kan ifrågasätta hur den beter sig och varför. Jag har också lärt mig att stanna upp och andas i svåra situationer där det är lätt att bli stressad/orolig och ta stressade beslut. Stanna upp och tänk, vad behöver jag?",
      details: "Madeleine, 27 år",
    },
  ];

  function test() {
    const element = ref.current;
    element?.classList.add("animate__animated", "animate__fadeInLeft");
    element?.addEventListener("animationend", () => {
      element?.classList.remove("animate__animated", "animate__fadeInLeft");
    });

    if (reviewNum === 1) {
      return (
        <div className="review" ref={ref}>
          <h2 className="title">Omdömen</h2>
          <p>{reviewsContent[0].description}</p>
          <span>
            <i>{reviewsContent[0].details}</i>
          </span>
        </div>
      );
    } else if (reviewNum === 2) {
      return (
        <div className="review" ref={ref}>
          <h2 className="title">Omdömen</h2>
          <p>{reviewsContent[1].description}</p>
          <span>
            <i>{reviewsContent[1].details}</i>
          </span>
        </div>
      );
    } else if (reviewNum === 3) {
      return (
        <div className="review" ref={ref}>
          <h2 className="title">Omdömen</h2>
          <p>{reviewsContent[2].description}</p>
          <span>
            <i>{reviewsContent[2].details}</i>
          </span>
        </div>
      );
    } else if (reviewNum === 4) {
      return (
        <div className="review" ref={ref}>
          <h2 className="title">Omdömen</h2>
          <p>{reviewsContent[3].description}</p>
          <span>
            <i>{reviewsContent[3].details}</i>
          </span>
        </div>
      );
    } else if (reviewNum === 5) {
      return (
        <div className="review" ref={ref}>
          <h2 className="title">Omdömen</h2>
          <p>{reviewsContent[4].description}</p>
          <span>
            <i>{reviewsContent[4].details}</i>
          </span>
        </div>
      );
    } else if (reviewNum === 6) {
      return (
        <div className="review" ref={ref}>
          <h2 className="title">Omdömen</h2>
          <p>{reviewsContent[5].description}</p>
          <span>
            <i>{reviewsContent[5].details}</i>
          </span>
        </div>
      );
    } else if (reviewNum === 7) {
      return (
        <div className="review" ref={ref}>
          <h2 className="title">Omdömen</h2>
          <p>{reviewsContent[6].description}</p>
          <span>
            <i>{reviewsContent[6].details}</i>
          </span>
        </div>
      );
    }
  }

  function prevReview() {
    if (reviewNum !== 1) {
      setReviewNum(reviewNum - 1);
    } else {
      setReviewNum(7);
    }
  }

  function nextReview() {
    if (reviewNum !== 7) {
      setReviewNum(reviewNum + 1);
    } else {
      setReviewNum(1);
    }
  }

  return (
    <div className="home animate__animated animate__fadeIn">
      <section className="hero-sec">
        <div className="layout">
          <h1>
            VÄLKOMMEN TILL <br /> IMPACT MIND
          </h1>
          <p>
            Om du mår dåligt, känner dig ensam, är stressad och orolig inför
            framtiden, <br /> chatta med oss så hjälper vi dig att hitta den
            stöd du behöver
          </p>
        </div>
      </section>

      <section className="news-sec pd-y">
        <h2>NYHETER</h2>
        <div className="posts">
          {posts.map((post) => (
            <div key={post.id} className="post">
              <img src={post.image} alt="" />
              <div className="layout">
                <span>{post.date}</span>
                <Link to={`/single-post/${post.id}/${post.title}`}>
                  <h3>{post.title}</h3>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="reviews-sec">
        <div className="arrow">
          <ArrowBackIosNewIcon
            sx={{ fontSize: 35, cursor: "pointer" }}
            onClick={prevReview}
          />
        </div>
        {test()}
        <div className="arrow">
          <ArrowForwardIosIcon
            sx={{ fontSize: 35, cursor: "pointer" }}
            onClick={nextReview}
          />
        </div>
        <div className="dots">
          <CircleIcon
            sx={{ fontSize: 8, margin: "0 6px", cursor: "pointer" }}
            onClick={() => setReviewNum(1)}
          />
          <CircleIcon
            sx={{ fontSize: 8, margin: "0 6px", cursor: "pointer" }}
            onClick={() => setReviewNum(2)}
          />
          <CircleIcon
            sx={{ fontSize: 8, margin: "0 6px", cursor: "pointer" }}
            onClick={() => setReviewNum(3)}
          />
          <CircleIcon
            sx={{ fontSize: 8, margin: "0 6px", cursor: "pointer" }}
            onClick={() => setReviewNum(4)}
          />
          <CircleIcon
            sx={{ fontSize: 8, margin: "0 6px", cursor: "pointer" }}
            onClick={() => setReviewNum(5)}
          />
          <CircleIcon
            sx={{ fontSize: 8, margin: "0 6px", cursor: "pointer" }}
            onClick={() => setReviewNum(6)}
          />
          <CircleIcon
            sx={{ fontSize: 8, margin: "0 6px", cursor: "pointer" }}
            onClick={() => setReviewNum(7)}
          />
        </div>
      </section>
      <section id="contact" className="contact-sec pd-y">
        <h2 className="title">Inga kommande evenemang för tillfället</h2>
        <div className="content">
          <span>Kontakta oss på följande:</span>
          <div className="emails">
            <p>martin@impactmind.se</p>
            <p>sima@impactmind.se</p>
          </div>
        </div>
      </section>
      <div className="under-contact-img">
        <img src={homeImg2} alt="" />
      </div>
    </div>
  );
}
