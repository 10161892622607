import React from "react";
import "./css/news.css";
import img1 from "../assets/images/post1.webp";
import img2 from "../assets/images/post2.webp";
import { NavHashLink as Link } from "react-router-hash-link";

export default function News() {
  let posts = [
    {
      id: 1,
      date: "17 feb.",
      title: "Vi lanserar en ny hemsida!",
      body: " Föreningen Impact Mind lanserar idag sin egen hemsida! Vi fortfarande håller på att arbeta med innehållet, men det går redan att läsa...",
      image: img1,
    },
    {
      id: 2,
      date: "21 jan.",
      title: "Bli medlem i föreningen",
      body: "Vill du bli en del av oss? Välkommen som medlem i föreningen Impact Mind! Som medlem i föreningen Impact Mind får du följande..",
      image: img2,
    },
  ];
  return (
    <div className="news animate__animated animate__fadeIn">
      <section className="posts-sec">
        <span className="span">Alla nyheter</span>
        <div className="posts">
          {posts.map((post, index) => (
            <div key={index} className="post">
              <img src={post.image} alt="" />
              <div className="content">
                <span className="date">{post.date}</span>
                <Link to={`/single-post/${post.id}/${post.title}`}>
                  <h3 className="text">{post.title}</h3>
                  <p className="text">{post.body}</p>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}
