import "./App.css";
import AboutUs from "./components/About us/AboutUs";
import DataPolicy from "./components/About us/DataPolicy";
import Statutes from "./components/About us/Statutes";
import Footer from "./components/Footer";
import FormsOfTherapy from "./components/FormsOfTherapy";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import News from "./components/News";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SupportUs from "./components/SupportUs";
import SinglePost from "./components/SinglePost";
import FamilyHome from "./components/FamilyHome";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/news" element={<News />} />
          <Route path="/forms-of-therapy" element={<FormsOfTherapy />} />
          <Route path="/familjehem" element={<FamilyHome/>}/>
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/statutes" element={<Statutes />} />
          <Route path="/data-policy" element={<DataPolicy />} />
          <Route path="/support-us" element={<SupportUs />} />
          <Route path="/single-post/:id/:postName" element={<SinglePost />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
