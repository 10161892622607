import React from "react";
import "./aboutUs.css";

export default function AboutUs() {
  return (
    <div className="about animate__animated animate__fadeIn">
      <div className="content">
        <h2>OM OSS</h2>
        <p>
          Här på föreningen Impact Mind vet vi att rätt stöd ibland är det enda
          som krävs för att åstadkomma förändring.
        </p>
        <p>
          Föreningen grundades 2021 och har sitt ändamål att att stödja
          människor som brottas med mental ohälsa. Detta ämnar att stärka deras
          självförtroende, höja självkänsla, minska stress, oro/ångest och
          därigenom få ett bättre fungerande vardag.
        </p>
        <p>
          Vi erbjuder webbinarier, individuell- och gruppterapi via digitala
          plattformar för att underlätta för att delta, mötas och lära sig på
          distans.
        </p>
        <p>Huvudsakliga verksamhetsområden:</p>
        <ul>
          <li>Information</li>
          <li>Föreläsningar (webbinarier)</li>
          <li>Föräldrautbildning</li>
          <li>Psykoterapeutiska samtal </li>
          <li>
            Personalutbildning för stresshantering, oro, ångest och depression
          </li>
        </ul>
        <p>
          Impact Mind är en icke vinstdrivande ideell förening vilket innebär
          att vi är oberoende av både religion och politik. Vi tror att
          tillgänglighet ökar möjlighet att få snabbare stöd för att minska
          psykiskt lidande. Vår ambition är att psykisk hälsa och samtal ska ha
          rimliga kostnader för att många skulle kunna ha nytta av det. För att
          kunna genomföra denna idé har vi valt att ha videosamtal i stället för
          att betala för dyra lokaler. Vi strävar efter att vara tillgängliga
          och erbjuda professionell behandling med rimliga priser. Vi tror att
          med hög tillgänglighet och professionell behandling med låga priser
          kan flera ha nytta av våra tjänster. “Alla människors lika värde” - vi
          välkomnar och erbjuder hjälp till människor oavsett ålder, bakgrund,
          könstillhörighet, sexuell läggning, religion osv.
        </p>
        <p>
          Vi har utvecklat våra program och föreläsningar efter behovet i vårt
          samhälle som är i ständig förändring genom olika internetlösningar. Vi
          erbjuder tillgänglighet, kostnadseffektivitet och professionella
          evidensbaserade behandlingar och interventioner. Vi har skapat en rad
          skräddarsydda behandlingar, till exempel har vi utbildning för
          föräldrar/anhöriga med ADHD/ADD diagnos.
        </p>
      </div>
    </div>
  );
}
