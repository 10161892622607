import React from "react";
import "./css/FOT.css";

export default function FormsOfTherapy() {
  let therapies = [
    {
      id: "1",
      title: "CFT - COMPASSIONFOKUSERAD TERAPI",
      content: [
        {
          p: "Compassionfokuserad terapi (CFT) är en modern stress- och känslohanteringsmetod. Metoden integrerar och kompletterar psykodynamisk terapi och KBT med modern hjärnforskning/neuropsykologi och meditativa traditioner. Det engelska ordet compassion står ungefär för självmedkänsla på svenska. Metoden lär oss att genom utveckla compassion mot oss själva och andra utveckla vår inre trygghetssystemet. Det kan vara värdefullt särskilt för personer med psykos/bipolära sjukdomar och depression. Dessa personer lider ofta av stigma och självkritik.",
        },
        {
          p: "Att förstå oss själva bättre är det första steget på vägen till att vara medkännande med oss själva. Så vår första nyckeltanke är då att vi inte utformade våra hjärnor eller önskningar och motiv eller kapacitet för vissa känslor. Därför är mycket av det som händer i vårt sinne kopplat till dess design och är INTE VÅRT FEL. Faktum är att många av våra känslor, våra tendenser till ilska och ångest, våra önskningar bli älskad, omhändertagen och respekterad, eller försöker hårt att undvika att bli avvisad och kritiserad, är inbyggda i våra gamla hjärnor.",
        },
        {
          p: "CFT lär oss om känsloreglering som vi ofta rör oss emellan dvs. hotsystem, drivsystem och trygghetssystem. CFT lär människan att identifiera när hen t.ex. hamnar i hotsystemet eller blir stressad/orolig av för mycket prestationskrav och vad hen kan göra för att få stilla sinnet och kropp.",
        },
        {
          p: "Det positiva med CFT är att fokus inte bara på att minska lidandet utan att få hen att lära sig tycka om sig själv, vara sin egen bästa vän, trösta och acceptera sig själv vid känslor av misslyckande eller hopplöshet. CFT erbjuder verktyg att kunna leva det liv vi vill med hjälp av olika mind training.",
        },
      ],
    },
    {
      id: "2",
      title: "ACT - ACCEPTANCE AND COMMITMENT TERAPI",
      content: [
        {
          p: "ACT går ut på att lära sig agera på ett sätt som fungerar och som tar oss loss när vi på olika sätt har fastnat i våra tankar och känslor. ACT handlar om två saker; 1. Vad är viktigt i livet 2. Ta konkreta steg i riktning mot vad man tycker är viktigt. ACT handlar om hur språk och kognitiva förmågan utvecklas, hur kommunikation och tänkande kan minska psykisk lidande.",
        },
      ],
    },
    {
      id: "3",
      title: "KBT - KOGNITIV BETEENDETERAPI",
      content: [
        {
          p: "Kognitiv beteendeterapi (KBT) är ett samlingsnamn för psykoterapier där man tränar på att använda sig av nya beteenden och tankemönster för att minska psykologiska lidande.",
        },
        {
          p: "Syftet med terapin är att tillsammans med terapeuten undersöka de tankar, känslor och beteenden som man antar skapar psykologiska besvär, och därmed minska lidande och smärta hos klienten.",
        },
        {
          p: "Oavsett vilket problem som behandlas, kännetecknas KBT av ett strukturerat upplägg och ett undersökande samarbete mellan terapeuten och klienten. Klienten informeras om arbetssättet, och sedan kartlägger man med en så kallad beteendeanalys vad som utlöser problemen och gör att de kvarstår.",
        },
        {
          p: "KBT ges ofta vid ett tillfälle per vecka under 5–20 veckor. ",
        },
      ],
    },
    {
      id: "4",
      title: "PROBLEMATIK",
      content: [
        {
          p: "Vi erbjuder tillgänglighet, kostnadseffektivitet och professionella evidensbaserade behandlingar och interventioner. Vi har skapat en rad skräddarsydda behandlingar till exempel vid depression, stress, social ångest, generaliserad ångest, kris, sorg, posttraumatisk stressyndrom (PTSD), föräldrastöd, låg självkänsla, stödsamtal för ADHD/ADD, ilskehantering och annat.",
        },
      ],
    },
  ];
  return (
    <div className="F-O-T pd-y animate__animated animate__fadeIn">
      <h2>TERAPIFORMER</h2>
      <div className="therapy-forms">
        {therapies.map((therapy, index) => (
          <div id={therapy.id} key={index} className="form">
            <h3>{therapy.title}</h3>
            {therapy.content.map((p, index) => (
              <p key={index}>{p.p}</p>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
