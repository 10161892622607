import React from "react";
import "./statutes.css";

export default function Statutes() {
  return (
    <div className="statutes animate__animated animate__fadeIn">
      <div className="content">
        <h2>STADGAR</h2>
        <div className="statute">
          <h3>§ 1 Föreningens firma</h3>
          <p>Föreningens firma är Impact Mind.</p>
        </div>
        <div className="statute">
          <h3>§ 2 Föreningens ändamål</h3>
          <p>
            Föreningen har som ändamål att stödja människor, framförallt unga
            och kvinnor, som brottas med mental ohälsa genom aktiviteter,
            seminarier och individuella terapeutiska samtal. Detta ämnar att
            stärka deras självförtroende och därigenom få ett välfungerande
            vardagsliv.
          </p>
        </div>
        <div className="statute">
          <h3>§ 3 Föreningens säte</h3>
          <p>Föreningen har sitt huvudsäte i Stockholm</p>
        </div>
        <div className="statute">
          <h3>§ 4 Medlemskap</h3>
          <p>
            Föreningen är öppen för alla som delar och vill verka för
            föreningens mål enligt § 2 och som följer Impact Minds stadgar.
            Medlemskapet söks skriftligt och erhålls genom lösandet av
            medlemsavgift. Avstängning och uteslutning av medlem kan beslutas av
            styrelsen, då synnerliga skäl föreligger, exempelvis vid verksamhet
            eller uppfödning som motarbetar eller strider mot föreningens mål
            och syfte. Eller vid medlem som uppenbarligen skadar föreningens
            intresse eller ger föreningen dålig publicitet eller rykte. Fråga om
            uteslutning får inte avgöras förrän medlem fått tillfälle att yttra
            sig inom viss, av föreningsstyrelsen, angiven tid om minst 14 dagar.
            I beslutet ska skälen för uteslutning redovisas. Beslutet ska sändas
            den berörde inom 7 dagar efter beslutet. Medlem som själv önskar
            utträda ur förening ska skriftligen eller muntligen vid årsmöte
            meddela detta till styrelsen och anses därmed omedelbart (eller
            efter mötets avslutande) ha lämnat föreningen, ingen återbetalning
            av erlagd medlemsavgift sker.
          </p>
        </div>
        <div className="statute">
          <h3>§ 5 Medlemsavgifter</h3>
          <p>
            Medlem ska betala den medlemsavgift som årligen fastställs av
            årsmötet.
          </p>
        </div>
        <div className="statute">
          <h3>§ 6 Styrelsen</h3>
          <p>
            Styrelsen består av ordförande och minst två (2) övriga ledamöter
            jämte. Styrelsen utser inom sig ordförande, sekreterare, kassör och
            de övriga poster föreningen anser sig behöva. Vid ledamots avsägelse
            adjungeras annan medlem av föreningen, intill nästkommande årsmöte.
          </p>
        </div>
        <div className="statute">
          <h3>§ 7 Styrelsens uppgifter</h3>
          <p>
            Styrelsen företräder föreningen, bevakar dess intressen och handhar
            dess angelägenheter. Styrelsen beslutar å föreningens vägnar såvida
            inte annat föreskrivs i dessa stadgar. Styrelsen ska verkställa av
            årsmötet fattade beslut, handha föreningens ekonomiska
            angelägenheter och föra räkenskaper, samt avge årsredovisning till
            årsstämman för det senaste räkenskapsåret. Styrelsen sammanträder
            när ordföranden finner det erforderligt eller om minst tre
            styrelseledamöter begär detta.
          </p>
          <p>
            Styrelsen är beslutsför då minst tre (3) personer är närvarande.
            Styrelsebeslut fattas med enkel majoritet. Vid lika röstetal gäller
            den mening ordföranden biträder, dock sker avgörandet vid val genom
            lottning.
          </p>
          <p>
            Föreningens firma tecknas av förenings ordförande och/eller ledamot
            som därtill utses ”styrelsen utser inom sig” firmatecknare.
          </p>
        </div>
        <div className="statute">
          <h3>§ 8 Räkenskapsår</h3>
          <p>Räkenskapsår ska vara kalenderår.</p>
        </div>
        <div className="statute">
          <h3>§ 9 Årsmöte</h3>
          <p>
            Ordinarie årsmöte, vilket är föreningens högsta beslutande organ,
            hålls årligen före den sista april på tid och plats som styrelsen
            bestämmer. Kallelse via hemsida och via av medlem anmäld mejladress
            ska utannonseras senast tre (3) veckor före ordinarie årsmöte.
          </p>
          <h4>Vid ordinarie årsmöte ska följande ärenden behandlas:</h4>
          <ol>
            <li>
              Val av ordförande och sekreterare för mötet, val av två (2)
              protokolljusterare.
            </li>
            <li>Fråga om mötet har utlysts på rätt sätt.</li>
            <li>Fastställande av dagordning.</li>
            <li>
              <ul>
                <li>
                  Styrelsens verksamhetsberättelse för det senaste
                  verksamhetsåret.
                </li>
                <li>
                  Styrelsens balans- och resultaträkning för det senaste
                  verksamhets-/räkenskapsåret.
                </li>
              </ul>
            </li>
            <li>Revisionsberättelsen för verksamhets-/räkenskapsåret.</li>
            <li>
              Fråga om ansvarsfrihet för styrelsen för den tid revisionen avser.
            </li>
            <li>Fastställande av medlemsavgifter.</li>
            <li>
              Fastställande av ev. Verksamhetsplan och behandling av budget för
              det kommande verksamhets-/räkenskapsåret.
            </li>
            <li>Val av ordförande i föreningen för en tid av två (2) år.</li>
            <li>Val av övriga styrelseledamöter för en tid av två (2) år.</li>
            <li>Val av revisor.</li>
            <li>
              Behandling av styrelsens förslag och i rätt tid inkomna motioner.
            </li>
            <li>Övriga frågor.</li>
          </ol>
          <p>
            Beslut i frågor av större ekonomisk betydelse för föreningen eller
            dess medlemmar får inte fattas om detta inte funnits med i kallelsen
            till mötet. Förslag som skall behandlas på årsmötet (utöver övriga
            frågor) ska inkomma minst en månad före datumet för årsmötet.
          </p>
        </div>
        <div className="statute">
          <h3>§ 10 Extra årsmöte</h3>
          <p>
            Extra årsmöte hålls när styrelsen eller revisorerna finner att det
            är nödvändigt eller när minst 2/3 av föreningens medlemmar kräver
            detta genom en skriftlig begäran till styrelsen. Av begäran ska
            framgå det eller de ärenden som medlemmarna vill att mötet ska
            behandla. På extra årsmöte får endast de ärenden som angivits i
            kallelse behandlas.
          </p>
        </div>
        <div className="statute">
          <h3>§ 11 Rösträtt</h3>
          <p>
            Vid årsmöte har varje medlem en röst. Rösträtten är personlig och
            kan inte utövas genom ombud.
          </p>
        </div>
        <div className="statute">
          <h3>§ 12 Regler för ändring av stadgarna</h3>
          <p>
            För ändring av dessa stadgar krävs beslut av årsmöte med minst 2/3
            av antalet avgivna röster. Förslag till ändring av stadgarna får ges
            såväl av medlem som styrelsen.
          </p>
        </div>
        <div className="statute">
          <h3>§ 13 Upplösning av föreningen</h3>
          <p>
            För upplösning av föreningen krävs beslut av årsmöte med minst 2/3
            av antalet avgivna röster. Om föreningen upplöses ska föreningens
            tillgångar överlämnas till vid årsmötet vald verksamhet eller
            bevarande projekt med liknande syfte eller ändamål.
          </p>
        </div>
      </div>
    </div>
  );
}
