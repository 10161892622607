import React from "react";
import "./css/footer.css";

export default function Footer() {
  return (
    <section className="footer-sec">
      <div className="socials">
        <a href="#">
          <i class="fa-brands fa-facebook-f"></i>
        </a>
        <a href="#">
          <i class="fa-brands fa-instagram"></i>
        </a>
        <a href="#">
          <i class="fa-brands fa-linkedin-in"></i>
        </a>
      </div>
      <span className="title">©2022 av Föreningen Impact Mind</span>
    </section>
  );
}
