import React from "react";
import "./dataPolicy.css";

export default function DataPolicy() {
  return (
    <div className="data-policy animate__animated animate__fadeIn">
      <div className="content">
        <h2>DATAPOLICY</h2>
        <span>Antagen 2022-03-25</span>
        <ol>
          {/*New list */}
          <li>
            <h3>Introduktion</h3>

            <p>
              Dataskyddsförordningen (GDPR, The General Data Protection
              Regulation) gäller i hela EU och har till syfte att skapa en
              enhetlig och likvärdig nivå för skyddet av personuppgifter.
              Dataskyddsförordningen ersätter personuppgiftslagen (PUL), som
              tidigare reglerade hur företag får samla in och använda
              personuppgifter i Sverige. Hela texten till Dataskyddsförordningen
              finns på Integritetsskyddsmyndighetens webbplats:
              <a href="https://www.imy.se/verksamhet/dataskydd/det-har-galler-enligt-gdpr/introduktion-till-gdpr/dataskyddsforordningen-i-fulltext/">
                https://www.imy.se/verksamhet/dataskydd/det-har-galler-enligt-gdpr/introduktion-till-gdpr/dataskyddsforordningen-i-fulltext/
              </a>
            </p>
            <p>
              Denna förordning ska tillämpas på sådan behandling av
              personuppgifter som helt eller delvis företas på automatisk väg
              samt på annan än automatisk behandling av personuppgifter som
              ingår i eller kommer att ingå i ett register.
            </p>
            <h4>Personuppgifter</h4>
            <p>
              Varje upplysning som avser en identifierad eller identifierbar
              fysisk person, varvid en identifierbar fysisk person är en person
              som direkt eller indirekt kan identifieras särskilt med hänvisning
              till en identifierare som ett namn, ett identifikationsnummer, en
              lokaliseringsuppgift eller online identifikatorer eller en eller
              flera faktorer som är specifika för den fysiska personens fysiska,
              fysiologiska, genetiska, psykiska, ekonomiska, kulturella eller
              sociala identitet.
            </p>
            <h4>Behandling av personuppgifter</h4>
            <p>
              En åtgärd eller kombination av åtgärder beträffande
              personuppgifter eller uppsättningar av personuppgifter, oberoende
              av om de utförs automatiserat eller ej, såsom insamling,
              registrering, organisering, strukturering, lagring, bearbetning
              eller ändring, framtagning, läsning, användning, utlämning genom
              överföring, spridning eller tillhandahållande på annat sätt,
              justering eller sammanförande, begränsning, radering eller
              förstöring.
            </p>
            <h4>Känsliga personuppgifter</h4>
            <p>
              Personuppgifter som avslöjar ras eller etniskt ursprung, politiska
              åsikter, religiös eller filosofisk övertygelse eller medlemskap i
              fackförening samt genetiska uppgifter, biometriska uppgifter som
              kan entydigt identifiera en fysisk person, uppgifter om hälsa
              eller uppgifter om en fysisk persons sexualliv eller sexuella
              läggning.
            </p>
            <span>
              Behandling av sådana uppgifter är förbjuden enligt
              Dataskyddsförordningen med några undantag bland annat:
            </span>
            <ul className="ul-dot">
              <li>
                Behandlingen är nödvändig av hänsyn till ett viktigt allmänt
                intresse, på grundval av unionsrätten eller medlemsstaternas
                nationella rätt, vilken ska stå i proportion till det
                eftersträvade syftet, vara förenligt med det väsentliga
                innehållet i rätten till dataskydd och innehålla bestämmelser om
                lämpliga och särskilda åtgärder för att säkerställa den
                registrerades grundläggande rättigheter och intressen.
              </li>
              <li>
                Behandlingen är nödvändig av skäl som hör samman med
                förebyggande hälso- och sjukvård och yrkesmedicin, bedömningen
                av en arbetstagares arbetskapacitet, medicinska diagnoser,
                tillhandahållande av hälso- och sjukvård, behandling, social
                omsorg eller förvaltning av hälso- och sjukvårdstjänster och
                social omsorg och av deras system, på grundval av unionsrätten
                eller medlemsstaternas nationella rätt eller enligt avtal med
                yrkesverksamma på hälsoområdet och under förutsättning att
                övriga villkor och skyddsåtgärder är uppfyllda.
              </li>
            </ul>
            <h4>Personuppgiftsansvarig</h4>
            <p>
              En fysisk eller juridisk person, offentlig myndighet, institution
              eller annat organ som ensamt eller tillsammans med andra bestämmer
              ändamålen och medlen för behandlingen av personuppgifter; om
              ändamålen och medlen för behandlingen bestäms av unionsrätten
              eller medlemsstaternas nationella rätt kan den
              personuppgiftsansvarige eller de särskilda kriterierna för hur
              denne ska utses föreskrivs i unionsrätten eller i medlemsstaternas
              nationella rätt.
            </p>
            <h4>Personuppgiftsbiträde</h4>
            <p>
              En fysisk eller juridisk person, offentlig myndighet, institution
              eller annat organ som behandlar personuppgifter för den
              personuppgiftsansvariges räkning.
            </p>
            <h4>Personuppgiftsincident</h4>
            <p>
              En säkerhetsincident som leder till oavsiktlig eller olaglig
              förstöring, förlust eller ändring eller till obehörigt röjande av
              eller obehörig åtkomst till de personuppgifter som överförts,
              lagrats eller på annat sätt behandlats.
            </p>
            <h4>Samtycke av den registrerade</h4>
            <p>
              Varje slag av frivillig, specifik, informerad och otvetydig
              viljeyttring, genom vilken den registrerade, antingen genom ett
              uttalande eller genom en entydig bekräftande handling, godtar
              behandling av personuppgifter som rör honom eller henne.
            </p>
            <h4>Tredje part</h4>
            <p>
              En fysisk eller juridisk person, offentlig myndighet, institution
              eller organ som inte är den registrerade, den
              personuppgiftsansvarige, personuppgiftsbiträdet eller de personer
              som under den personuppgiftsansvariges eller
              personuppgiftsbiträdes direkta ansvar är behöriga att behandla
              personuppgifterna.
            </p>
          </li>
          {/*New list */}
          <li>
            <h3>Personuppgiftsansvarig</h3>

            <p>
              Föreningen Impact Mind är ansvarig för den behandlingen av
              personuppgifter som lämnas till den. Som personuppgiftsansvarig
              bestämmer föreningen över ändamålet och medlen för behandlingen.
            </p>
            <h4>Kontaktuppgifter</h4>
            <p>
              Namn: Ideella föreningen Impact Mind
              <br />
              Organisationsnummer: 802533-7497
              <br />
              Adress: ℅ Olof Svenhamn, Lindholmsbacken 142, 127 49 Skärholmen
              <br />
              Telefon: +46(0)76-927 53 20
              <br />
              E-post: martin.walian@gmail.com
            </p>
            <p>
              Vid frågor eller för att utföra sina rättigheter t.ex. återkalla
              sitt samtycke, ska man kontakta föreningen via e-post eller
              telefon.
            </p>
            <p>
              Det går alltid att tacka nej till direktmarknadsföring eller
              mejlutskick. Det snabbaste sättet att göra så är genom att trycka
              “avprenumerera” (“unsubscribe” på engelska) i e-postmeddelandet.
            </p>
            <p>
              Den som anser att dennes personuppgifter behandlas i strid med
              gällande rätt får lämna in ett klagomål direkt till oss eller till
              Integritetsskyddsmyndigheten.
            </p>
            <p>
              Föreningen kan komma att ändra den här policyn. Alla ändringar ska
              annonseras på hemsidan samt i nyhetsbrev. Enligt lag ska
              föreningen också spara alla tidigare versioner av dokumentet.
            </p>
          </li>
          {/*New list */}
          <li>
            <h3>Behandling av personuppgifter</h3>

            <p>
              För att behandla personuppgifter krävs det rättslig grund.
              Föreningens behandling av personuppgifter sker utifrån de grunder
              som fastställs i Dataskyddsförordningen.
            </p>
            <span>
              De rättsliga grunder som vår personuppgiftsbehandling normalt
              genomförs utifrån är följande:
            </span>
            <ul className="ul-dot">
              <li>
                <b>Samtycke</b>, vilket innebär att den vars personuppgifter vi
                behandlar har valt att godkänna att vi behandlar uppgifterna
                genom att ge sitt samtycke.
              </li>
              <li>
                <b>Avtal</b>, vilket betyder att behandlingen är nödvändigt för
                att fullgöra ett avtal i vilket den registrerade är part eller
                för att vidta åtgärder på begäran av den registrerade innan ett
                sådant avtal ingås;
              </li>
              <li>
                <b>Rättslig skyldighet</b> som åvilar den
                personuppgiftsansvarige, till exempel rapportering av skatter;
              </li>
              <li>
                <b>Allmänt intresse</b>, och även viktigt allmänt intresse,
                vilket innebär arbete inom hälso- och sjukvård och social
                omsorg.
              </li>
              <li>
                <b>Intresseavvägning</b>, ändamål som rör den
                personuppgiftsansvariges eller en tredje parts berättigade
                intressen, om inte den registrerades intressen eller
                grundläggande rättigheter och friheter väger tyngre och kräver
                skydd av personuppgifter, särskilt när den registrerade är ett
                barn.
              </li>
            </ul>
            <span>
              Personuppgiftsincidenter anmälas till Datainspektionen inom 72
              timmar vid vetskap, den registrerade informeras om det föreligger
              hög risk för enskildas rättigheter och friheter, till exempel:
            </span>
            <ul className="ul-dot">
              <li>att den enskilde förlorar kontrollen över sina uppgifter;</li>
              <li>att den registrerades rättigheterna inskränks;</li>
              <li>
                att den registrerade utsätts för diskriminering, identitetsstöld
                eller bedrägeri;
              </li>
              <li>
                att den registrerade råkar ut för finansiell förlust, skadlig
                ryktesspridning, brott mot sekretess eller tystnadsplikt.
              </li>
            </ul>
            <span>
              Förutsedda tidsfristerna för radering av de olika kategorierna av
              uppgifter:
            </span>
            <ul className="ul-dot">
              <li>
                enligt svenska bokföringslagen, måste organisationens bokföring
                lagras i minst sju år, det betyder att alla personuppgifter som
                är kopplade till föreningens bokföring får raderas minst sju år
                efter att de var utförda;
              </li>
              <li>
                när det gäller uppgifter om patienter agerar föreningen som
                personuppgiftsbiträde till sina uppdragsgivare, vilket innebär
                att den behandlar personuppgifter för uppdragsgivarens räkning
                samt enbart enligt instruktionen från den. Representanter av
                föreningen Impact Mind hanterar patienternas uppgifter i
                uppdragsgivarens system och därmed har ett begränsat ansvar
                först och främst om att personuppgifterna hanteras på ett säkert
                sätt, däremot det är uppdragsgivaren som ansvarar för lagringen
                av uppgifter i deras egna system.
              </li>
              <li>
                uppgifter om föreningens medlemmar samlas för att administrera
                löpande aktiviteter kopplade till föreningens verksamhet (tex.
                medlemsmöten) och kommunicera med medlemmarna (kallelser till
                aktiviteter och skicka relevanta nyhetsbrev och uppdateringar)
                och ska raderas från medlemskapssystemet senast 1 år efter att
                en person har slutat vara medlem i föreningen;
              </li>
              <li>
                när det gäller frivillig (med den registrerades samtycke)
                utlämning av personuppgifter (t.ex. att ha kontakt på en
                webbplats), bör de raderas från offentliga platser eller andra
                frivilliga register inom en månad efter uppgifterna inte längre
                behövs för de ändamål som de samlades in för eller ifall
                personen återkallar sitt samtycke, om den registrerades samtycke
                tillät behålla personuppgifter (till exempel bilder i sociala
                medier) under längre tid.
              </li>
            </ul>
            <p>
              Förteckningen över behandlingen av personuppgifter förs av
              föreningen Impact Mind och på begäran kan föreningen göra ett
              utdrag ur registret.
            </p>
          </li>
          {/*New list */}
          <li>
            <h3>Den registrerades rättigheter</h3>

            <p>
              I enlighet med gällande lagstiftning har den registrerade ett
              antal rättigheter som innebär att denne kan få information om och
              kontroll över egna personuppgifter. Nedan listas dessa rättigheter
              och i kapitel 2 går det bra att hitta kontaktuppgifter om man vill
              göra sina rättigheter gällande. Den registrerade har normalt rätt
              att utnyttja sina rättigheter kostnadsfritt. Om begäran är
              uppenbart ogrundad eller orimlig får dock föreningen antingen ta
              ut en rimlig avgift eller välja att inte tillmötesgå begäran.
            </p>
            <ul className="ul-alpha">
              <li>
                <h5>Rätt till information och tillgång till personuppgifter</h5>
                <p>
                  Den registrerade har rätt att begära bekräftelse på om
                  föreningen behandlar personuppgifter om denne och om så är
                  fallet kommer föreningen att informera den registrerade om hur
                  dennes personuppgifter behandlas. Den registrerade har även
                  rätt att få en kopia av de uppgifterna som föreningen
                  behandlar (genom ett registerutdrag). Om den registrerade
                  begär ytterligare kopior har föreningen rätt att ta ut en
                  rimlig avgift för detta.
                </p>
              </li>
              <li>
                <h5>Rätt till rättelse</h5>
                <p>
                  Det är viktigt för föreningen att de personuppgifter som vi
                  har om den registrerade är korrekta. Om uppgifterna är
                  felaktiga har den registrerade rätt att vända sig till
                  föreningen som är personuppgiftsansvarig och be om att få
                  uppgifterna rättade. Den registrerade har även rätt att begära
                  att uppgifter läggs till om något saknas, om tillägget är
                  relevant med hänsyn till ändamålet med behandlingen. Vi kommer
                  att underrätta dem, till vilka vi har lämnat ut den
                  registrerades uppgifter, om att rättelse har skett. Föreningen
                  kommer även att på begäran informera den registrerade om till
                  vem/vilka information om rättelse har lämnats ut.
                </p>
              </li>
              <li>
                <h5>Rätt att bli raderad</h5>
                <span>
                  Den registrerade har rätt att vända sig till föreningen för
                  att begära att personuppgifter raderas:
                </span>
                <ul className="ul-dot">
                  <li>
                    Om uppgifterna inte längre behövs för de ändamål som de
                    samlades in för;
                  </li>
                  <li>
                    Om behandlingen grundar sig enbart på den registrerades
                    samtycke och denne återkallar samtycket;
                  </li>
                  <li>
                    Om behandlingen sker för direktmarknadsföring och den
                    registrerade motsätter sig att uppgifterna behandlas;
                  </li>
                  <li>
                    Om den registrerade motsätter sig personuppgiftsbehandling
                    som sker efter en intresseavvägning och det inte finns
                    berättigade skäl som väger tyngre än dennes intresse;
                  </li>
                  <li>
                    Om behandlingen av personuppgifter inte har följt gällande
                    rätt; eller
                  </li>
                  <li>
                    Om radering krävs för att uppfylla en rättslig skyldighet.
                  </li>
                </ul>
                <p>
                  I vissa fall kan föreningen dock inte tillmötesgå en begäran
                  om radering t.ex. om vi är enligt lag skyldiga att spara
                  uppgifterna. Om radering sker kommer föreningen att underrätta
                  dem, till vilka vi har lämnat ut den registrerades uppgifter,
                  om att radering har skett. Föreningen kommer även att på
                  begäran informera den registrerade om till vem/vilka
                  information om radering har lämnats ut.
                </p>
              </li>
              <li>
                <h5>Rätt att göra invändningar</h5>
                <ul className="ul-dot">
                  <li>
                    Den registrerade har alltid rätt att när som helst invända
                    mot att dennes uppgifter används för direktmarknadsföring.
                    Det gör man genom att kontakta föreningen alternativt
                    avprenumerera från nyhetsbrev. Vid en sådan invändning
                    kommer föreningen inte längre behandla uppgifterna för det
                    ändamålet;
                  </li>
                  <li>
                    Den registrerade har även rätt att invända mot att
                    föreningen behandlar dennes uppgifter med stöd av en
                    intresseavvägning. Om föreningen inte kan påvisa att det
                    finns tvingande berättigade skäl för behandlingen som väger
                    tyngre än den registrerades skäl ska behandlingen upphöra.
                  </li>
                </ul>
              </li>
              <li>
                <h5>Rätt till begränsning av behandling</h5>
                <span>
                  Den registrerade har rätt att kontakta föreningen och begära
                  att behandlingen av dennes personuppgifter begränsas och att
                  uppgifterna då enbart ska lagras i följande situationer:
                </span>
                <ul className="ul-dot">
                  <li>
                    Under den tid det tar för föreningen att kontrollera om
                    personuppgifterna är korrekta, om den registrerade bestrider
                    personuppgifternas korrekthet;
                  </li>
                  <li>
                    Om behandlingen är olaglig och den registrerade motsätter
                    sig att uppgifterna raderas och istället vill att föreningen
                    begränsar användningen av dessa;
                  </li>
                  <li>
                    Om den registrerade, trots att föreningen inte behöver
                    uppgifterna längre, vill att vi behåller dem för att denne
                    ska kunna använda dessa för att fastställa, göra gällande
                    eller försvara rättsliga anspråk; eller
                  </li>
                  <li>
                    I väntan på kontroll av vems berättigade skäl, den
                    registrerades eller föreningens, som väger tyngst om denne
                    har gjort en invändning mot behandlingen.
                  </li>
                </ul>
                <p>
                  I vissa fall kan föreningen dock inte tillmötesgå en begäran
                  om begränsning t.ex. om uppgifterna behövs för att vi ska
                  kunna försvara föreningens rättigheter eller skydda någon
                  annan persons rättigheter. Om begränsning sker kommer
                  föreningen att underrätta dem, till vilka vi har lämnat ut den
                  registrerades uppgifter, att begränsning har skett. Vi kommer
                  även att på begäran informera den registrerade om till
                  vem/vilka information om begränsning har lämnats ut.
                </p>
              </li>
              <li>
                <h5>Rätt till dataportabilitet</h5>
                <p>
                  Den registrerade har rätt att begära att få ut sina
                  personuppgifter i ett maskinläsbart format och har rätt
                  använda sådana personuppgifter på annat håll. Denna rättighet
                  gäller för sådana personuppgifter som denne har lämnat till
                  föreningen och som behandlas med stöd av samtycke eller om
                  behandlingen grundar sig på avtal med föreningen.
                </p>
              </li>
            </ul>
          </li>
          {/*New list */}
          <li>
            <h3>Datasäkerhet</h3>
            <p>
              Dokumentationen som innehåller personuppgifter hanteras och
              förvaras i elektroniska system som kräver lösenkod för behörig,
              avtal eller DPA (Data Protection Agreement) med
              personuppgiftsbiträde alternativt personuppgiftsansvariga där
              föreningen agerar som personuppgiftsbiträde finns tillgängliga.{" "}
            </p>
            <p>
              Personuppgiftsbiträde ansvarar för att upprätthålla tillräckliga
              organisatoriska och säkerhetslösningar, rapportera
              personuppgiftsincidenter i tid, upprätta registerförteckningar
              samt utse Dataskyddsombud i de fall det krävs enligt
              Dataskyddsförordningen. Personuppgiftsbiträde har begränsat ansvar
              för skadestånd om ej följt de skyldigheter som följer av
              Dataskyddsförordningen (GDPR), och/eller ej följt avtal eller
              instruktioner från personuppgiftsansvarig.
            </p>
            <span>
              Dokumentation får förvaras utanför EU/EES (till exempel, i
              molnbaserade tjänster) bara i följande fall:
            </span>
            <ul className="ul-dot">
              <li>
                När det finns ett beslut från EU-kommissionen om att exempelvis
                ett visst land utanför EU/EES säkerställer adekvat skyddsnivå.
              </li>
              <li>
                När en tjänst har vidtagit lämpliga skyddsåtgärder, till exempel
                bindande företagsbestämmelser (så kallade Binding Corporate
                Rules, BCR) eller standardavtalsklausuler (så kallade Standard
                Contractual Clauses, SCC).
              </li>
              <li>
                I särskilda situationer och enstaka fall, om mottagande
                organisation eller myndighet garanterar adekvat skyddsnivå (till
                exempel, ambassader, internationella organisationer och
                bidragsgivare).
              </li>
            </ul>
          </li>
        </ol>
      </div>
    </div>
  );
}
